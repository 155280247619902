@import "../../../scss/variables";

.context-menu {
  .header {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin: 0 10px 10px;
    border-bottom: 1px solid mat-color($app-foreground, divider-lighter);

    media-image {
      width: 80px;
      height: 80px;
      flex: 0 0 auto;
      margin-right: 15px;
    }

    .meta {
      margin-top: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.5rem;

      .primary-name {
        display: block;
        width: 100%;
        white-space: nowrap;
        color: inherit;
      }

      .secondary-name {
        display: block;
        color: mat-color($app-foreground, secondary-text);
        margin-top: 2px;
        width: 100%;
      }
    }
  }
}
