.be-container, .container {
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (min-width: 768px) {
    max-width: 750px;
  }

  @media screen and (min-width: 992px) {
    max-width: 970px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1170px;
  }
}