@import "variables";

.be-dark-mode .media-page-header {
  .nav-links-wrapper {
    border-bottom-color: mat-color($app-foreground, divider-lighter);
  }
}

.media-page-header {
  padding: 22px 28px 2px; // 2px needed to show box shadow
  position: relative;
  margin-bottom: 36px;
  overflow: hidden;

  .flex-container {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .header-content {
    flex: 1 1 auto;
    min-width: 0;
  }

  .header-image {
    display: block;
    width: 265px;
    height: 265px;
    flex-shrink: 0;
    margin-right: 30px;
    overflow: hidden;
    box-shadow: 0 1px 6px rgba(25,25,34,.16);

    &.round {
      border-radius: 50%;
    }
  }

  .title {
    position: relative;
    margin: 0;
    font-size: 3.2rem;

    @media only screen and (max-width: $medium) {
      font-size: 3.5rem;
    }
  }

  .subtitle {
    opacity: 0.7;
    font-size: 1.5rem;
    position: relative;
    margin: 0 0 30px;
    max-width: 600px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .action-buttons {
    position: relative;
    margin-bottom: 30px;
    white-space: nowrap;
  }

  .action-button {
    line-height: 40px;
    font-size: 1.5rem;
    font-weight: 400;
    border-radius: 25px;

    .mat-icon {
      line-height: 24px;
    }
  }

  .play-button {
    min-width: 120px;
    margin-right: 20px;

    .mat-icon {
      margin-right: 3px;
    }
  }

  .add-button {
    margin-right: 10px;

    .mat-icon {
      margin-right: 5px;
    }

    @media only screen and (max-width: $medium) {
      display: none;
    }
  }

  .mat-stroked-button {
    min-width: 100px;
  }

  .nav-links-wrapper {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid transparent;
  }

  .profile-links {
    margin-left: auto;
    padding-bottom: 6px;
  }

  .profile-link-image {
    display: inline-block;
    border-radius: 4px;
  }

  .nav-links {
    display: flex;
    margin-top: 48px;
    font-size: 1.6rem;
    box-shadow: 0 2px 6px -4px rgba(25,25,34,.16);
    border-bottom: 1px solid transparent;
    position: relative;
    z-index: 1;

    .nav-link {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      min-width: 120px;
      padding: 0 24px;
      text-align: center;
      border-bottom: 2px solid transparent;
      color: currentColor;
      white-space: nowrap;
      text-transform: capitalize;
      -webkit-tap-highlight-color: transparent;

      &:not(.active) {
        opacity: 0.7;
      }

      @media only screen and (max-width: $medium) {
        font-weight: 400;
      }

      &.active {
        border-color: mat-color($app-accent, default);
      }
    }
  }

  @media only screen and (max-width: $medium) {
    .flex-container {
      display: block;
    }

    .title {
      margin: 10px 0 20px;
    }

    .header-image {
      margin: 0 auto;
    }

    .header-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .subtitle-line {
      text-align: center;
      justify-content: center;
    }
  }
}
