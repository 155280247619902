@import "./variables";

.media-autocomplete-panel {
  max-height: 350px !important;

  .mat-option {
    height: 65px;
    line-height: 65px;
  }

  .mat-option-text {
    display: flex;
    align-items: center;
    font-size: 1.4rem;

    img {
      width: 45px;
      height: 45px;
      margin-right: 15px;
      border-radius: 50%;
      flex-shrink: 0;
      object-fit: cover;
    }

    > span {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .multiple-lines {
      line-height: normal;

      > .secondary {
        font-size: 1.3rem;
      }
    }
  }
}

.be-modal {
  .media-autocomplete-input-container {
    display: flex;
    flex-wrap: wrap;

    .mat-chip-list {
      display: block;
      width: 100%;
      margin: 10px 0 0 160px;
    }

    input {
      margin: 0;
      flex: 1 1;
    }

    img {
      width: 45px;
      height: 45px;
      flex: 0 0 45px;
      object-fit: cover;
      margin-right: 8px;
      border-radius: 4px;
    }

    p {
      flex: 0 0 100%;
      width: 100%;
      font-size: 1.3rem;
      color: inherit;
    }
  }
}
