/* You can add global styles to this file, and also import other style files */

@import "common-imports";
@import "container";
// @import "web-player-overlay-theme";
@import "media-page-header.scss";
@import "context-menu";
@import "../app/web-player/context-menu/web-player-context-menu";
@import "media-modal-autocomplete";

account-settings {
  padding: 15px;
}

body, html {
  height: 100%;
}
